import { useState, useEffect } from "react";

import Data from "./Data";
import Carousel from "./components/Carousel";
import Background from "./components/Background";
import Nav from "./components/Nav";

import "./styles/global-styles.scss";

function App() {

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 750 ? true : false
  );

  useEffect(() => {
    const setAppHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };

    const checkScreenWidth = () => {
      if (window.innerWidth < 750) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", setAppHeight);
    window.addEventListener("resize", checkScreenWidth);

    setAppHeight();
    checkScreenWidth();

    return () => {
      window.removeEventListener("resize", setAppHeight);
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);

  const [sectionIndex, setSectionIndex] = useState(0);

  // const data = {
  //   info: {
  //     month: "July",
  //     year: " 2024",
  //   },
  //   content: [
  //     {
  //       type: "releases",
  //       title: "Albums",
  //       content: [
  //         {
  //           image: "03.png",
  //           artist: "Various",
  //           title: "Sky is the Limit",
  //           label: {
  //             text: "Love on the Rocks",
  //             link: "https://ra.co/labels/10581",
  //           },
  //           articleLink: "https://ra.co/reviews/36132",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://loveontherocks.bandcamp.com/album/10-years-love-on-the-rocks-sky-is-the-limit",
  //             },
  //             {
  //               platform: "Spotify",
  //               link: "https://open.spotify.com/album/6Ruubyip5SQccnNFWBCRVq?si=e-A7Kc2iS2W_ij8k9G41Dg",
  //             },
  //             {
  //               platform: "Apple",
  //               link: "https://music.apple.com/us/album/limbo-in-heaven/1749048597",
  //             },
  //           ],
  //           notes:
  //             "Groovy enough for the heads, loud enough for the club—PARAMIDA's label turns ten with a typically high-class compilation.",
  //         },
  //         {
  //           image: "01.png",
  //           artist: "Qoa",
  //           title: "SAUCO",
  //           label: {
  //             text: "Leaving Records",
  //             link: "https://ra.co/labels/9486",
  //           },
  //           articleLink: "https://ra.co/reviews/36138",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://qoaqoa.bandcamp.com/album/sauco-2",
  //             },
  //             {
  //               platform: "Spotify",
  //               link: "https://open.spotify.com/album/2WUpERltTsCGVaTDyNriNv",
  //             },
  //             {
  //               platform: "Apple",
  //               link: "https://music.apple.com/us/album/sauco/1734858898",
  //             },
  //           ],
  //           notes: "Soothing ambient odes to the wilderness of Argentina.",
  //         },
  //         {
  //           image: "011.png",
  //           artist: "British Murder Boys",
  //           title: "Active Agents and House Boys",
  //           label: {
  //             text: "Downwards",
  //             link: "https://ra.co/labels/3443",
  //           },
  //           articleLink: "https://ra.co/reviews/36137",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://british-murder-boys.bandcamp.com/album/active-agents-and-house-boys",
  //             },
  //             {
  //               platform: "Spotify",
  //               link: "https://open.spotify.com/album/5nOnVqOOu7WvfQLGwYtIG7",
  //             },
  //             {
  //               platform: "Apple",
  //               link: "https://music.apple.com/us/album/active-agents-and-house-boys/1737255628",
  //             },
  //           ],
  //           notes:
  //             "Regis and Surgeon's debut album after two decades together fine-tunes their live sets into a dubby, post-punk snarl.",
  //         },
  //         {
  //           image: "016.png",
  //           artist: "Iceboy Voilet & Nueen",
  //           title: "You Said You'd Hold My Hand Through The Fire",
  //           label: {
  //             text: "Hyperdub",
  //             link: "https://ra.co/labels/806",
  //           },
  //           articleLink: "https://ra.co/reviews/36131",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://iceboyviolet.bandcamp.com/album/you-said-youd-hold-my-hand-through-the-fire",
  //             },
  //             {
  //               platform: "Spotify",
  //               link: "https://open.spotify.com/album/3gckYcKWJx3lBbuKbOWY4W",
  //             },
  //             {
  //               platform: "Apple",
  //               link: "https://open.spotify.com/album/3gckYcKWJx3lBbuKbOWY4W",
  //             },
  //           ],
  //           notes:
  //             "The Manchester rapper and producer debuts on Hyperdub with a corrosive ambient break-up album.",
  //         },
  //         {
  //           image: "013.png",
  //           artist: "Clara La San",
  //           title: "Made Mistakes",
  //           label: {
  //             text: "Self-released",
  //             link: "https://ra.co/labels/3443",
  //           },
  //           articleLink: "https://ra.co/reviews/36137",
  //           links: [
  //             {
  //               platform: "Spotify",
  //               link: "https://open.spotify.com/album/01fUH3E30d9sjlzNV3jNHu",
  //             },
  //             {
  //               platform: "Apple",
  //               link: "https://music.apple.com/us/album/made-mistakes/1740848910",
  //             },
  //           ],
  //           notes:
  //             "An immersive and intricate take on bedroom R&B built from a lush array of early '00s pop and nods to the softer sides of the UK hardcore continuum.",
  //         },
  //         {
  //           image: "012.png",
  //           artist: "Various",
  //           title: "Obligated Records: Vol. 1",
  //           label: {
  //             text: "OBLIGATED RECORDS",
  //             link: "https://ra.co/labels/3443",
  //           },
  //           articleLink: "https://ra.co/reviews/36137",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://obligatedrecords.bandcamp.com/album/obligated-records-vol-1",
  //             },
  //           ],
  //           notes:
  //             "This album shows what grime actually is, in all its different forms—two sides of the same rough-edged coin with techno.",
  //         },

  //         {
  //           image: "07.png",
  //           artist: "Actress",
  //           title: "Statik",
  //           label: {
  //             text: "SMALLTOWN SUPERSOUND",
  //             link: "https://ra.co/labels/1757",
  //           },
  //           articleLink: "https://ra.co/reviews/36137",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://actress.bandcamp.com/album/statik",
  //             },
  //             {
  //               platform: "Apple",
  //               link: "https://music.apple.com/us/album/statik/1737815989",
  //             },
  //           ],
  //           notes:
  //             "Statik rolls back Actress's recent free jazz curveballs and corrugated hip-hop beats to focus on windblown hiss and radio interference for a sense of uneasy calm.",
  //         },

  //         {
  //           image: "02.png",
  //           artist: "Kaytranada",
  //           title: "Timeless",
  //           label: {
  //             text: "RCA",
  //             link: "https://ra.co/labels/6138",
  //           },
  //           articleLink: "https://ra.co/reviews/36128",
  //           links: [
  //             {
  //               platform: "Spotify",
  //               link: "https://open.spotify.com/album/3C3t2bKhwEL3wdKioqWUDh",
  //             },
  //             {
  //               platform: "Apple",
  //               link: "https://music.apple.com/us/album/timeless/1747039538",
  //             },
  //           ],
  //           notes:
  //             "Kaytranada delivers his most exciting, upbeat record yet, a sexy dance odyssey at the intersection of R&B, hip-hop, pop and soul.",
  //         },

  //         {
  //           image: "014.png",
  //           artist: "Charli XCX",
  //           title: "Brat",
  //           label: {
  //             text: "Atlantic Records",
  //             link: "https://ra.co/labels/36119",
  //           },
  //           articleLink: "https://ra.co/reviews/36119",
  //           links: [
  //             {
  //               platform: "Spotify",
  //               link: "https://open.spotify.com/album/2lIZef4lzdvZkiiCzvPKj7",
  //             },
  //             {
  //               platform: "Apple",
  //               link: "https://music.apple.com/us/album/brat/1739079974?ls=1",
  //             },
  //           ],
  //           notes:
  //             "Brash, confident and proudly messy, Charli XCX's clubbiest album is also her most vulnerable—and best.",
  //         },

  //         {
  //           image: "017.png",
  //           artist: "Suzi Analogue",
  //           title: "ONEZ",
  //           label: {
  //             text: "Never Normal Records",
  //             link: "https://ra.co/labels/6138",
  //           },
  //           articleLink: "https://ra.co/reviews/36128",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://suzianalogue.bandcamp.com/album/onez",
  //             },
  //             {
  //               platform: "Spotify",
  //               link: "https://open.spotify.com/album/7zriAnpj3gGZ4d6vaep2Sn",
  //             },
  //             {
  //               platform: "Apple",
  //               link: "https://music.apple.com/us/album/onez/1736189342?ls=1",
  //             },
  //           ],
  //           notes:
  //             "The Never Normal Records boss drops a sizzling mixtape of tracks old and new just in time for the summer.",
  //         },
  //       ],
  //     },
  //     {
  //       type: "releases",
  //       title: "EPs",
  //       content: [
  //         {
  //           image: "06.png",
  //           artist: "Various",
  //           title: "030313",
  //           label: {
  //             text: "Tresor",
  //             link: "https://ra.co/labels/6138",
  //           },
  //           articleLink: "https://ra.co/reviews/36128",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://tresorberlin.bandcamp.com/album/030313",
  //             },
  //             {
  //               platform: "Spotify",
  //               link: "https://open.spotify.com/album/33YlTnBGt1tHyjZeAotux1",
  //             },
  //             {
  //               platform: "Apple",
  //               link: "https://music.apple.com/us/album/030313-ep/1736806888",
  //             },
  //           ],
  //           notes:
  //             "Carhartt WIP and Tresor team up for an EP that highlights the past, present and future of the unique, enduring Detroit-Berlin connection.",
  //         },

  //         {
  //           image: "09.png",
  //           artist: "Verraco",
  //           title: "Breathe... Godspeed",
  //           label: {
  //             text: "Timedance",
  //             link: "https://ra.co/labels/6138",
  //           },
  //           articleLink: "https://ra.co/reviews/36128",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://verraco.bandcamp.com/album/breathe-godspeed",
  //             },
  //             {
  //               platform: "Spotify",
  //               link: "https://open.spotify.com/album/3md1CdsAIbnLZ0dQRIfrVx",
  //             },
  //             {
  //               platform: "Apple",
  //               link: "https://music.apple.com/us/album/breathe-godspeed-ep/1742748627",
  //             },
  //           ],
  //           notes:
  //             "The TraTraTrax cofounder gets bigger and bolder on his debut for Timedance",
  //         },

  //         {
  //           image: "08.png",
  //           artist: "Yetsuby",
  //           title: "b_b",
  //           label: {
  //             text: "All My Thoughts",
  //             link: "https://ra.co/labels/6138",
  //           },
  //           articleLink: "https://ra.co/reviews/36128",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://yetsuby.bandcamp.com/album/b-b",
  //             },
  //           ],
  //           notes:
  //             "Part hyperpop, part weirdo club, part easy listening—the Salamanda cofounder operates in a world of her own.",
  //         },

  //         {
  //           image: "019.png",
  //           artist: "Carrier",
  //           title: "In Spectra",
  //           label: {
  //             text: "All My Thoughts",
  //             link: "https://ra.co/labels/6138",
  //           },
  //           articleLink: "https://ra.co/reviews/36128",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://0207carrier.bandcamp.com/album/in-spectra",
  //             },
  //           ],
  //           notes:
  //             "With Carrier, Guy Brewer looks back to his formative drum & bass years and rebuilds those sounds in a creative, and wizened, way.",
  //         },

  //         {
  //           image: "015.png",
  //           artist: "Boo",
  //           title: "Hyper Feminine",
  //           label: {
  //             text: "AMF",
  //             link: "https://ra.co/labels/18028",
  //           },
  //           articleLink: "https://ra.co/reviews/36103",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://boobub.bandcamp.com/track/hyper-feminine",
  //             },
  //             {
  //               platform: "Apple",
  //               link: "https://music.apple.com/us/album/winged-victory-ep/1745756001",
  //             },
  //           ],
  //           notes:
  //             "In just under five minutes, Boo builds a hypnotic techno soundscape where softness intertwines with grit.",
  //         },

  //         {
  //           image: "018.png",
  //           artist: "Robert Dietz",
  //           title: "3 Mirrors",
  //           label: {
  //             text: "NOUS'KLAER AUDIO",
  //             link: "https://ra.co/labels/10170",
  //           },
  //           articleLink: "https://ra.co/reviews/36103",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://nousklaer.bandcamp.com/album/3-mirrors",
  //             },
  //           ],
  //           notes:
  //             "A lowkey German lifer turns in a surprising record of emotional and dramatic techno for Nous'klaer Audio.",
  //         },

  //         {
  //           image: "010.png",
  //           artist: "INVT & Introspekt",
  //           title: "Tectónica",
  //           label: {
  //             text: "EC2A",
  //             link: "https://ra.co/labels/19175",
  //           },
  //           articleLink: "https://ra.co/reviews/36133",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://invt.bandcamp.com/album/opm007-invt-x-introspekt-tectonica",
  //             },
  //             {
  //               platform: "Spotify",
  //               link: "https://open.spotify.com/album/7x1BNRhhEhnEgTtuv260On",
  //             },
  //           ],
  //           notes:
  //             "Across four tracks, the trio find an unexpected sweet spot between 2-step and reggaeton.",
  //         },

  //         {
  //           image: "05.png",
  //           artist: "Gene On Earth",
  //           title: "The Velvet Edge",
  //           label: {
  //             text: "LIMOUSINE DREAM",
  //             link: "https://ra.co/labels/15624",
  //           },
  //           articleLink: "https://ra.co/reviews/36133",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://geneonearth.bandcamp.com/album/the-velvet-edge",
  //             },
  //           ],
  //           notes:
  //             "The American producer is taking on the big room without compromising his commitment to the funkiest—and weirdest—corners of dance music history.",
  //         },

  //         {
  //           image: "020.png",
  //           artist: "Tatyana Jane",
  //           title: "Elixyr",
  //           label: {
  //             text: "NAAFI",
  //             link: "https://ra.co/labels/5111",
  //           },
  //           articleLink: "https://ra.co/reviews/36114",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://tatyanajane.bandcamp.com/track/infernal-circle",
  //             },
  //           ],
  //           notes: "Hazy drum & bass-techno hybridism on N.A.A.F.I.",
  //         },

  //         {
  //           image: "04.png",
  //           artist: "Kanding Ray",
  //           title: "Polar",
  //           label: {
  //             text: "SPAZIO DISPONIBLE",
  //             link: "https://ra.co/labels/14293",
  //           },
  //           articleLink: "https://ra.co/reviews/36121",
  //           links: [
  //             {
  //               platform: "Bandcamp",
  //               link: "https://kangdingray.bandcamp.com/album/polar-ep",
  //             },
  //             {
  //               platform: "Spotify",
  //               link: "https://open.spotify.com/album/39sJr4ODaxsa9LrmL5XeJQ",
  //             },
  //           ],
  //           notes:
  //             "The French producer continues to drift away from peak time with an EP of beautiful and intricate ambient techno.",
  //         },
  //       ],
  //     },
  //     {
  //       type: "mixes",
  //       title: "Mixes",
  //       content: [
  //         {
  //           title: "Bazaar 71",
  //           label: {
  //             text: "NATHALIE SERES",
  //             link: "https://ra.co/dj/nathalieseres",
  //           },
  //           image: "SC_01.png",
  //           articleLink:
  //             "https://soundcloud.com/tofistock/bazaar71-nathalie-seres-vinyl-mix",
  //           embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1819617939&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
  //           notes: `Panorama Bar resident Nathalie Seres puts together a wonderfully paced Bazaar mix that starts out house and gets wobbly and bouncy from there, climaxing with Pangaea's "Installation."`,
  //         },

  //         {
  //           title: "Extasy Overnight Guest Mix",
  //           label: {
  //             text: "GBENGA",
  //             link: "",
  //           },
  //           image: "SC_02.png",
  //           articleLink: "https://soundcloud.com/extasyovernight/benga-mix",
  //           embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1827586860&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
  //           notes: `The multidisciplinary artist and Serenity Link resident joins Los Angeles rave and mix series Extasy, delivering an hour of barrelling techno, trance and reggaeton-inflected club edits, including their own rework of JT's "Okay."`,
  //         },

  //         {
  //           title: "NTS",
  //           label: {
  //             text: "Daria Kolosova",
  //             link: "https://ra.co/dj/dariakolosova",
  //           },
  //           image: "SC_03.png",
  //           articleLink:
  //             "https://soundcloud.com/user-643553014/daria-kolosova-040624",
  //           embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1838271429&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
  //           notes: `The Lutugino-born DJ is one of the post-pandemic era's breakout stars, with frequent appearances at Awakenings, Berghain and Bassiani. For the first show in her NTS Radio residency, she steps up for an hour of what she does best: heavy-hitting techno on an old-school tip, including a barreling rework of M.I.A's "Bucky Done Gun."`,
  //         },

  //         {
  //           title: "Neptunian Influences 67",
  //           label: {
  //             text: "JS ALVAREZ",
  //             link: "",
  //           },
  //           image: "SC_04.png",
  //           articleLink:
  //             "https://soundcloud.com/neptunianinfluence/ni67-js-alvarez",
  //           embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1843046148&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
  //           notes: `The Podlasie resident steps up for the latest instalment of the Detroit podcast and event series Neptunian Influence, delivering a hefty 90-minute mix of sub-heavy rollers, mutant percussion and post-apocalyptic electro.`,
  //         },

  //         {
  //           title: "Waterworks Artist Mix #1",
  //           label: {
  //             text: "CALIBRE",
  //             link: "https://ra.co/dj/calibre",
  //           },
  //           image: "SC_05.png",
  //           articleLink:
  //             "https://soundcloud.com/waterworksfestival/waterworks-artist-mix-1-calibre",
  //           embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1845616674&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
  //           notes: `Spanning 90 minutes of dubstep, UK garage and drum & bass, this live recording from last year's Waterworks Festival in London perfectly sums up Calibre's signature soulful sound.`,
  //         },

  //         {
  //           title: "GMIX002",
  //           label: {
  //             text: "JWY",
  //             link: "https://ra.co/dj/jwy-1",
  //           },
  //           image: "SC_06.png",
  //           articleLink: "https://soundcloud.com/gmixseries/gmix002",
  //           embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1825153857&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
  //           notes: `Dublin-based DJ and Foxgluv founder JWY delivers the goods on the second instalment of the GMIX SERIES. With propulsive tracks from the likes of Amor Satyr and Ulterior Motive, it might make you reconsider a quiet night in.`,
  //         },

  //         {
  //           title: "Residencias Mariposa",
  //           label: {
  //             text: "FORTUNATO",
  //             link: "",
  //           },
  //           image: "SC_07.png",
  //           articleLink:
  //             "https://soundcloud.com/mariposabog/indogma-invita-a-fortunato-residencias-mariposa",
  //           embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1852309065&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
  //           notes: `For the third edition of Mariposa's programme linking artists in the Global South, the Colombian collective invites Brazilian producer and DJ Fortunato for an hour of pumping techno, electro and Jersey club.`,
  //         },

  //         {
  //           title: "Lentekabinet 2024",
  //           label: {
  //             text: "FAFI ABDEL NOUR",
  //             link: "https://ra.co/dj/fafiabdelnour",
  //           },
  //           image: "SC_08.png",
  //           articleLink:
  //             "https://soundcloud.com/lapifilia/music-series-016-lyo-xs",
  //           embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1812005394&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
  //           notes: `Running the gamut from old-school techno to tech house, with plenty of sultry vocals along the way, this two-hour recording is from the Amsterdam festival's 2024 edition.`,
  //         },

  //         {
  //           title: "Kiosk Radio: Summer Sound",
  //           label: {
  //             text: "CHUCHEEWA",
  //             link: "https://ra.co/dj/chucheewa",
  //           },
  //           image: "SC_09.png",
  //           articleLink:
  //             "https://soundcloud.com/kioskradio/summer-sound-w-chucheewa-kiosk",
  //           embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1829551599&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
  //           notes: `The Bangkok-based DJ, singer and instrumentalist delivers a steady stream of groovy house cuts for Brussels' Kiosk Radio, from some old-school Green Velvet and Jex Opolis to Alex Kassian's rendition of Manuel Göttsching's "E2-E4."`,
  //         },
  //       ],
  //     },
  //   ],
  // };

  return (
    <div className="App">
      <Nav
        data={Data}
        sectionIndex={sectionIndex}
        setSectionIndex={setSectionIndex}
      />
      <Background data={Data} info={Data.info} sectionIndex={sectionIndex} />
      <div className="carousel__container">
        <div
          className="carousel__outer-wrapper"
          style={{
            top: !isMobile ? `-${sectionIndex * 100}vh` : 0,
            left: isMobile ? `-${sectionIndex * 100}vw` : 0,
          }}
        >
          {Data.content.map((section, index) => {
            return (
              <Carousel
                data={section}
                isMobile={isMobile}
                key={index}
                sectionIndex={sectionIndex}
                index={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default App;
