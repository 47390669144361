import React from "react";

const Nav = (props) => {
  const { data, sectionIndex, setSectionIndex } = props;

  return (
    <div className="nav__wrapper">
      {data.content.map((section, index) => {
        return (
          <button
            className={`nav__button ${sectionIndex === index?'active':''}`}
            onClick={() => setSectionIndex(index)}
            key={index}
            // style={{
            //     top: sectionIndex >= index? `calc(${index * 3}vw)`: `calc(100vh - ${index * 3}vw)`
            // }}
          >
            
                <div className={`nav__dash-wrapper ${sectionIndex === index?'active':''}`}>
                    <div className="nav__dash"></div>
                </div>
                <div className="nav__text"><h1>Best {section.title}</h1></div>
                
          </button>
        );
      })}
    </div>
  );
};

export default Nav;
