import { useEffect, useRef, useState } from "react";
import Paragraph from "./Paragraph";

const Record = (props) => {
  const { record, index, currentIndex, carouselIndex, sectionIndex, isMobile } =
    props;

  const wrapper = useRef();
  const [viewState, setViewState] = useState("none");
  const [textPos, setTextPos] = useState("one");

  useEffect(() => {
    const value = Math.floor(Math.random() * 3);

    switch (value) {
      case 0:
        setTextPos("one");
        break;
      case 1:
        setTextPos("two");
        break;
      case 2:
        setTextPos("three");
        break;
      default:
        setTextPos("one");
        break;
    }
  }, []);

  useEffect(() => {
    if (index === currentIndex) {
      setViewState("none");
    } else if (index === currentIndex - 1) {
      setViewState("prev");
    } else if (index < currentIndex - 1) {
      setViewState("left");
    } else if (index === currentIndex + 1) {
      setViewState("next");
    } else if (index > currentIndex + 1) {
      setViewState("right");
    }
  }, [currentIndex, index]);

  const [rotation, setRotation] = useState({ x: 0, y: 0 });
  const [mousePos, setMousePos] = useState({
    x: window.innerWidth / 2,
    y: window.innerHeight / 2,
  });

  useEffect(() => {
    const updateMouse = (e) => {
      if (!isMobile) {
        setMousePos({
          x: e.clientX,
          y: e.clientY,
        });
      }
      // setMousePos({
      //   x: !isMobile ? e.clientX : window.innerWidth / 2,
      //   y: !isMobile ? e.clientY : window.innerHeight / 2,
      // });
    };

    window.addEventListener("mousemove", updateMouse, false);

    return () => {
      window.removeEventListener("mousemove", updateMouse, false);
    };
  }, []);

  useEffect(() => {
    const rotateElement = () => {
      const middleX = window.innerWidth / 2;
      const middleY = window.innerHeight / 2;

      const offsetX = !isMobile ? ((mousePos.x - middleX) / middleX) * 30 : 0;
      const offsetY = !isMobile ? ((mousePos.y - middleY) / middleY) * 30 : 0;

      let newRotation;
      if (!isMobile) {
        if (index === currentIndex) {
          newRotation = { x: offsetY, y: offsetX };
        } else if (index === currentIndex - 1) {
          newRotation = { x: offsetY, y: offsetX + 30 };
        } else if (index < currentIndex - 1) {
          newRotation = { x: offsetY, y: offsetX + 40 };
        } else if (index === currentIndex + 1) {
          newRotation = { x: offsetY, y: offsetX - 30 };
        } else if (index > currentIndex + 1) {
          newRotation = { x: offsetY, y: offsetX - 40 };
        }
      } else {
        if (index === currentIndex) {
          newRotation = { x: offsetY, y: 0 };
        } else if (index === currentIndex - 1) {
          newRotation = { x: offsetY - 30, y: 0 };
        } else if (index < currentIndex - 1) {
          newRotation = { x: offsetY - 40, y: 0 };
        } else if (index === currentIndex + 1) {
          newRotation = { x: offsetY + 30, y: 0 };
        } else if (index > currentIndex + 1) {
          newRotation = { x: offsetY + 40, y: 0 };
        }
      }

      // if(isMobile){

      // }

      setRotation(newRotation);
    };

    rotateElement();
    return;
    // window.addEventListener("mousemove", rotateElement);
    // window.addEventListener("mousedown", rotateElement);

    // return () => {
    //   window.removeEventListener("mousemove", rotateElement);
    //   window.removeEventListener("mousedown", rotateElement);
    // };
  }, [mousePos, currentIndex]);

  return (
    <>
      <div
        className={`carousel__text-box ${
          currentIndex === index && carouselIndex === sectionIndex
            ? "active"
            : ""
        }`}
      >
        {/* <div className="carousel__text-inner"><Paragraph data={record.notes} /></div> */}
        <Paragraph data={record.notes} />

      </div>
      <div
        className={`carousel__titles ${
          currentIndex === index && carouselIndex === sectionIndex
            ? "active"
            : ""
        }`}
      >
        <div className="carousel__title label">
          <h2>
            {record.label.link ? (
              <a href={record.label.link} target="_blank" rel="noreferrer">
               <span>{record.label.text}</span>
              </a>
            ) : (
              <span>{record.label.text}</span> 
            )}
          </h2>
        </div>
        <div className="carousel__title">
            <a href={record.articleLink} target="_blank" rel="noreferrer">
              <span>{record.artist} — {record.title}</span>
            </a>
        </div>
      </div>

      <div className={`carousel__record-outer ${viewState}`}>
        <div
          className={`carousel__record-cover ${viewState}`}
          ref={wrapper}
          style={{
            transform: !isMobile
              ? `translate(-50%, -50%) rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`
              : `rotateX(${rotation.x}deg) rotateY(${rotation.y}deg)`,
            backgroundImage: `url('./images/${record.image}')`,
          }}
        ></div>
      </div>

      <div
        className={`carousel__listen ${
          currentIndex === index && carouselIndex === sectionIndex
            ? "active"
            : ""
        }`}
      >
        <h2 className="carousel__listen-title"><span>LISTEN:</span></h2>
        {record.links.map((link, index) => {
          return (
            <a href={link.link} rel="noreferrer" target="_blank" key={index}>
              <h2>{link.platform}</h2>
            </a>
          );
        })}
      </div>
    </>
  );
};

export default Record;
