const Paragraph = (props) => {
  const { data } = props;

  function convertQuotesToItalic(text) {
    return text.replace(
      /<([^>]*)>|\[\[link:(https?:\/\/[^\]]+)\], \[text: ([^\]]+)\]\]|\*\[\[link:(https?:\/\/[^\]]+)\],\s*\[text:\s*([^\]]+)\]\]\*/g,
      (match, p2, url, linkText, italicUrl, italicLinkText) => {
        if (p2 !== undefined) {
          return `<em>${p2}</em>`;
        } else if (url !== undefined && linkText !== undefined) {
          return `<a href="${url}" target="_blank" rel="noreferrer">${linkText}</a>`;
        } else if (italicUrl !== undefined && italicLinkText !== undefined) {
          return `<a href="${italicUrl}" target="_blank" rel="noreferrer"><em>${italicLinkText}</em></a>`;
        }
        return match;
      }
    );
  }

  const processedText = convertQuotesToItalic(data);

  return <p dangerouslySetInnerHTML={{ __html: processedText }}></p>;
};


export default Paragraph;
// <Italic>
// [[link:https://example.com], [text: EXAMPLE]]