import React from "react";

const BackgroundSection = (props) => {
  const { info, data } = props;
  return (
    <div className="background__section">
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
      <div className="background__cell">
        <span className="background__text">
          Best {data.title} – {info.month}, {info.year}
        </span>
      </div>
    </div>
  );
};

export default BackgroundSection;
