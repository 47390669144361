const Data = {
    info: {
      month: "July",
      year: " 2024",
    },
    content: [
      {
        type: "releases",
        title: "Albums",
        content: [
          {
            image: "heis-cover.webp",
            artist: "HEIS",
            title: "REMA",
            label: {
              text: "Mavin Records",
              link: "https://ra.co/labels/24336",
            },
            articleLink: "https://ra.co/reviews/36160",
            links: [
              {
                platform: "Apple",
                link: "https://music.apple.com/us/album/heis/1755408609",
              },
            ],
            notes:
              "The Nigerian superstar returns with a vengeance on his second album, <HEIS>. Rema squashes the summery loverboy croons he made his name on, turning instead to thundering percussion and combative lyrics that signal his blazing victory lap.",
          },
          {
            image: "kamixlo-lp-cover.webp",
            artist: "Kamixlo",
            title: "Deathwork",
            label: {
              text: "PAN",
              link: "https://ra.co/labels/7166",
            },
            articleLink: "https://ra.co/reviews/36162",
            links: [
              {
                platform: "Bandcamp",
                link: "https://kamixlo.bandcamp.com/album/deathwork",
              },
            ],
            notes:
              "On Kamixlo's second album, modern dance music's obsession with rock continues to flourish. The former Bala Club founder takes the anything-goes sound of those club nights, joining the brash lyricism of nu metal and with the serrated rhythms of industrial reggaeton.",
          },

          {
            image: "thenulife-cover.webp",
            artist: "Christian AB",
            title: "The Nu Life",
            label: {
              text: "Faith Beat",
              link: "https://ra.co/labels/17111",
            },
            articleLink: "https://ra.co/reviews/36161",
            links: [
              {
                platform: "Bandcamp",
                link: "https://faithbeat.bandcamp.com/album/faith-beat-08-the-nu-life-lp",
              },
            ],
            notes:
              "Christian AB's debut album is a gold mine for working DJs. Simple, effective and stripped down, the record is a double-pack of some of the most elegant house you'll hear all year.",
          },

          {
            image: "bubblingforever-cover.webp",
            artist: "De Schuurman",
            title: "Bubbling Forever",
            label: {
              text: "Nyege Nyege Tapes",
              link: "https://ra.co/labels/15492",
            },
            articleLink: "https://ra.co/reviews/36159",
            links: [
              {
                platform: "Bandcamp",
                link: "https://nyegenyegetapes.bandcamp.com/album/bubbling-forever",
              },
            ],
            notes:
              "The bubbling producer's second compilation on Nyege Nyege showcases a tougher iteration of the Dutch electronic music genre. The latest look into his work offers infectious, high-energy club music made for a single purpose: making you sweat.",
          },

          {
            image: "a0399079093_10.jpg",
            artist: "Mabe Fratti",
            title: "Sentir Que No Sabes",
            label: {
              text: "Triangle",
              link: "https://ra.co/promoters/72903",
            },
            articleLink: "https://tinangelrecords.bandcamp.com/album/sentir-que-no-sabes",
            links: [
              {
                platform: "Bandcamp",
                link: "https://tinangelrecords.bandcamp.com/album/sentir-que-no-sabes",
              },
            ],
            notes:
              "The experimental Mexico City-based artist's latest album might be her most approachable yet. While making the album, she took a melody-first approach, leading to songwriting that sits front and centre of the music, spooling upbeat lyricism into her signature maelstroms of cello.",
          },

          {
            image: "zulilambda-cover.webp",
            artist: "ZULI",
            title: "LABDA",
            label: {
              text: "Subtext",
              link: "https://ra.co/labels/481",
            },
            articleLink: "https://ra.co/reviews/36147",
            links: [
              {
                platform: "Bandcamp",
                link: "https://zulimusic.bandcamp.com/album/lambda",
              },
            ],
            notes:
              "On <Lambda>, ZULI trades chaotic club workouts for dissonant ambient. The result is his most intimate and, surprisingly, accessible release yet.",
          },

        ],
      },
      {
        type: "releases",
        title: "EPs",
        content: [
          {
            image: "doctorjeepmecha-cover.webp",
            artist: "Doctor Jeep",
            title: "Mecha",
            label: {
              text: "TraTraTrax",
              link: "https://ra.co/labels/18226",
            },
            articleLink: "https://ra.co/reviews/36152",
            links: [
              {
                platform: "Bandcamp",
                link: "https://doctorjeep.bandcamp.com/album/mecha",
              },
              {
                platform: "Apple",
                link: "https://music.apple.com/us/album/mecha/1749282326",
              },
            ],
            notes:
              "The New York artist suffuses his sharp and laminated club rhythms with his Brazilian heritage. On <Mecha>, he brings together Pikachu samples, baile funk MCs and ridiculously good remixes from Ploy, Wata Igarashi and Peder Mannerfelt.",
          },

          {
            image: "Olof-Dreijer-1717715086-1000x1000.jpg",
            artist: "Olof Dreijer & Diva Cruz",
            title: "Brujas",
            label: {
              text: "Dekmantel",
              link: "https://ra.co/labels/3521",
            },
            articleLink: "",
            links: [
              {
                platform: "Bandcamp",
                link: "https://olofdreijer.bandcamp.com/album/brujas",
              },
            ],
            notes:
              "Diva Cruz makes her vocal debut on <Brujas>, where she stares patriarchy and colonialism down with fierce lyrics over beefy drums with help from The Knife's percussionist Olof Dreijer.",
          },

          {
            image: "bodytalk-cover.webp",
            artist: "Nectax",
            title: "Body Talk",
            label: {
              text: "Play:Musik",
              link: "https://ra.co/labels/24284",
            },
            articleLink: "https://ra.co/reviews/36165",
            links: [
              {
                platform: "Bandcamp",
                link: "https://nectax.bandcamp.com/album/body-talk-ep",
              },
            ],
            notes:
              "The first EP in 14 years on DJ Flight's label showcases the stylistic diversity of drum & bass. For Play:Musik's relaunch, Nectax whips footwork, techno and hardcore into the genre with finesse.",
          },

          {
            image: "freakyhorns-cover.webp",
            artist: "JIALING",
            title: "Freaky Horns",
            label: {
              text: "Clasico",
              link: "https://ra.co/labels/21983",
            },
            articleLink: "https://ra.co/reviews/36154",
            links: [
              {
                platform: "Bandcamp",
                link: "https://jialing.bandcamp.com/album/freaky-horns",
              },
            ],
            notes:
              "When Janie Shih started putting out music, she wasn't afraid to proudly fly the flag of her hometown Baltimore. <Freaky Horns> still waves the Baltimore flag high, but also touches on acid, rave and house.",
          },

          {
            image: "masha-pulltostop-cover.webp",
            artist: "Ma Sha",
            title: "Pull To Stop",
            label: {
              text: "Steel City Dance Discs",
              link: "https://ra.co/labels/13991",
            },
            articleLink: "https://ra.co/reviews/36166",
            links: [
              {
                platform: "Bandcamp",
                link: "https://ma-sha.bandcamp.com/album/pull-to-stop",
              },
            ],
            notes:
              "The Kindergarten Records founder does what she knows best: producing brain-melting club music with metallic drums that bounce with the force of a ricocheting pinball.",
          },
        ],
      },
      {
        type: "mixes",
        title: "Mixes",
        content: [
          {
            title: "Freerotation 2024",
            label: {
              text: "Softi",
              link: "https://ra.co/dj/jazz",
            },
            image: "July-mix_07.jpg",
            articleLink:
              "https://soundcloud.com/jazz_brown/free-rotation-softi-05_07_24",
            embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1819617939&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
            notes: `Marrying foggy textures with squelchy earworms, the rising DJ's ability to craft vivid psychedelic landscapes from a wide palette of sounds is on show in this hour-long recording from cult Welsh festival Freerotation. One for any Animalia or Dorisburg fans.`,
          },
          {
            title: "Animix One Hundred & Sixty One",
            label: {
              text: "Vlada",
              link: "https://ra.co/dj/vlada",
            },
            image: "July-mix_04.jpg",
            articleLink:
              "https://soundcloud.com/animalia-label/animix-one-hundred-sixty-one-vlada-july2024",
            embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1819617939&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
            notes: `Listening to a Vlada mix is to fall down the rabbit hole, locking you in with tunes you've most likely never heard before and are unlikely to ever find. Her mix for Kia's Animalia label is no different—good luck Shazaming.`,
          },

          {
            title: "Beach Noir",
            label: {
              text: "Nick León",
              link: "https://ra.co/dj/nickleon",
            },
            image: "July-mix_01.jpg",
            articleLink:
              "https://soundcloud.com/user-643553014/beach-noir-w-nick-leon-160724",
            embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1819617939&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
            notes: `For his debut NTS Radio show, Nick León gets introspective. Delivering a love letter to his home city of Miami, the now London-based DJ and producer goes deep on moody ambient and IDM cuts (with a touch of folk). Who said romance is dead?`,
          },

          {
            title: "fabric podcast 042",
            label: {
              text: "Neffa-T",
              link: "https://ra.co/dj/neffa-t",
            },
            image: "July-mix_06.jpg",
            articleLink:
              "https://soundcloud.com/fabric/042-fabric-podcast-neffa-t",
            embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1819617939&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
            notes: `Six-deck wizard and CDJ final boss battle Neffa-T brings his usual mind-melting blend of grime, 140 and techno to this edition of fabric's podcast series, ahead of his appearance at the London club this Friday.`,
          },

          {
            title: "The Lot Radio 06-29-2024",
            label: {
              text: "DJ Marcelle",
              link: "https://ra.co/dj/djmarcelle",
            },
            image: "July-mix_05.jpg",
            articleLink:
              "https://soundcloud.com/thelotradio/dj-marcelle-the-lot-radio-06-29-2024",
            embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1819617939&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
            notes: `Genre-defying and one-of-a-kind are two descriptors that get thrown around a lot in dance music, but DJ Marcelle fits that bill. This 60-minute mix for New York's The Lot Radio sees the cult DJ in fine avant-garde form, stringing together Tanzanian singeli, oddball techno and speed dembow to delirious effect.`,
          },


          {
            title: "Repercussion Podcast 020",
            label: {
              text: "Jane Olsen",
              link: "https://ra.co/dj/janeolsen",
            },
            image: "July-mix_03.jpg",
            articleLink:
              "https://soundcloud.com/we-r-repercussion/janeolsen-repercussion-june22",
            embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1819617939&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
            notes: `Tāmaki Makaurau-based DJ Jane Olsen goes seriously deep on the 20th episode of the Repercussion Podcast series, curating an hour of thick and foggy psychedelic cuts from the likes of Oltrefuturo, Young Druid and Vanessa Worm.`,
          },


          {
            title: "Dekmantel 472",
            label: {
              text: "Konduku",
              link: "https://ra.co/dj/konduku",
            },
            image: "July-mix_02.jpg",
            articleLink:
              "https://soundcloud.com/dkmntl/dekmantel-podcast-472-konduku",
            embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1819617939&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
            notes: `Konduku has been releasing properly hypnotic techno on the likes of Nous'Klaer Audio, Idle Hands and Bitta for the best part of a decade.

            For his Dekmantel mix, the Berlin-based DJ and producer delivers a stellar foray into his foggy, psychedelic sound. Give it a spin, and you'll begin to see where the DJ Nobu and Dozzy comparisons come from.
            `,
          },

          {
            title: "BOG By Night",
            label: {
              text: "Latesleeper",
              link: "",
            },
            image: "latesleeper.png",
            platform: "Youtube",
            articleLink:
              "https://www.youtube.com/watch?v=vfuKRciSU60&ab_channel=ClockFactory",
            embedSrc: `https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1819617939&color=%23000000&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true`,
            notes: `Bristol-based producer and DJ Latesleeper cruises through 45 minutes of razor-sharp drum & bass and jungle for Clock Factory's BOG By Night series. If this doesn't make you want to go out raving, nothing will.`,
          },
        ],
      },
    ],
  };

  export default Data;