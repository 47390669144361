import { useEffect, useState, useRef, useCallback } from "react";
import Record from "./Record";
import Mix from "./Mix";

const Carousel = (props) => {
  const { data, sectionIndex, index, isMobile } = props;

  const carouselInner = useRef();
  const cropWrapper = useRef();
  const [currentIndex, setCurrentIndex] = useState(data.content.length);
  const [infiniteData, setInfiniteData] = useState([
    ...data.content,
    ...data.content,
    ...data.content,
  ]);

  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setCurrentIndex(data.content.length);
  }, [data.content.length]);

  const scrollToImage = () => {
      const spacerOffset = window.innerHeight * 0.55;
      const imageOffset = window.innerWidth / 2 - window.innerHeight * 0.275;
      const scrollLeftValue =
        spacerOffset * currentIndex + spacerOffset - imageOffset;
      setLeft(-scrollLeftValue);

      const spacerOffsetMobile = window.innerWidth ;
      const scrollTopValue = spacerOffsetMobile * currentIndex + spacerOffsetMobile - 110;

      setTop(-scrollTopValue)

      
  };

  useEffect(() => {
    scrollToImage(currentIndex);

    const handleResize = () => {
      scrollToImage(currentIndex);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [currentIndex, scrollToImage]);

  const handleNext = () => {
    if (currentIndex === infiniteData.length - 2) {
      setInfiniteData([...infiniteData, ...infiniteData]);
    }
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrev = () => {
    if (currentIndex === 1) {
      setInfiniteData([...infiniteData, ...infiniteData]);
      setCurrentIndex((prevIndex) => prevIndex + infiniteData.length);
    } else {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  useEffect(() => {
    if (cropWrapper.current) {
      cropWrapper.current.classList.add("disabled");

      setTimeout(() => {
        if (cropWrapper.current) {
          cropWrapper.current.classList.remove("disabled");
        }
      }, 400);
    }
  }, [currentIndex, sectionIndex]);

  return (
    <>
      <button
        className={`carousel__button prev ${
          sectionIndex === index ? "active" : ""
        }`}
        onClick={handlePrev}
      >
        <div className="carousel__arrow prev" />
      </button>
      <button
        className={`carousel__button next ${
          sectionIndex === index ? "active" : ""
        }`}
        onClick={handleNext}
      >
        <div className="carousel__arrow next" />
      </button>

      <div className="carousel__vignette" />

      <div
        className="carousel__inner-wrapper"
        ref={carouselInner}
        style={{
          left: !isMobile? `${left}px`: `${index * 100}vw`,
          top: !isMobile? `${index * 100}vh`: `${top}px`
         }}
        // style={{
        //   left: !isMobile ? `${left}px` : `${index * 100}vw`,
        //   top: !isMobile ? `${index * 100}vh` : 0,
        // }}
      >
        <div className="carousel__spacer" />
        {infiniteData &&
          infiniteData.map((record, idx) => {
            if (data.type === "releases") {
              return (
                <Record
                  record={record}
                  key={idx}
                  index={idx}
                  sectionIndex={sectionIndex}
                  carouselIndex={index}
                  currentIndex={currentIndex}
                  isMobile={isMobile}
                />
              );
            } 
            else if (data.type === "mixes") {
              return (
                <Mix
                  record={record}
                  key={idx}
                  index={idx}
                  sectionIndex={sectionIndex}
                  carouselIndex={index}
                  currentIndex={currentIndex}
                  isMobile={isMobile}
                />
              );
            } else {
              return;
            }
          })}
        <div className="carousel__spacer" />
      </div>

      <div
        className={`carousel__crop-wrapper ${
          sectionIndex === index ? "active" : ""
        }`}
        ref={cropWrapper}
      >
        <div className="carousel__crop top left" />
        <div className="carousel__crop top right" />
        <div className="carousel__crop bottom left" />
        <div className="carousel__crop bottom right" />
      </div>
    </>
  );
};

export default Carousel;
