import BackgroundSection from "./BackgroundSection";

const Background = (props) => {
  const { info, data, sectionIndex } = props;

  return (
    <div className="background__wrapper">
      <div className="background__container"
        style={{
            width: `${data.content.length * 100}vw`,
            left: `-${sectionIndex * 100}vw`,
        }}>
        {data.content.map((section, index) => {
          return <BackgroundSection data={section} info={info} key={index} />
        })}
      </div>
    </div>
  );
};

export default Background;
